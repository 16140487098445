<template>
  <v-card>
    <v-row>
      <v-col :key="$index" v-for="(item, $index) in artworkDetails" xl= "2" lg="3" sm="4" cols="12" class="d-flex flex-column">
        <v-card
        :id="`item${$index}`"
        :key="$index"
        outlined
        class="mx-0 my-0 d-flex flex-column flex-grow-1 v-clickable"
      >
        <v-card-text class="d-flex flex-column">
          <!-- <v-img v-for="(product, index) of item.products" :key="index" :src="`${imageUrl}/100px/${product.artworkImage}`" ></v-img> -->
          <v-img @click="showProducts(item)" :src="`${imageUrl}/300px/${item.artworkImage}`">
          </v-img>
          <v-card-subtitle class="artwork-title px-0">
            {{ item.title }}
          </v-card-subtitle>
          <div><strong>Available as:</strong> {{item.productList}}</div>
        </v-card-text>
      </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loaded">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <artwork-products-dialog v-if="openDialog" v-model='openDialog' :item='selectedItem' @close="closeDialog" />
  </v-card>
</template>

<script>
import ArtworkProductsDialog from '@/views/Public/Artists/Artist/ArtworkProductsDialog.vue'
import pluralize from 'pluralize'
import _ from 'lodash'

export default {
  props: {
    artworks: Array
  },
  components: {
    ArtworkProductsDialog
  },
  directives: {
  },
  data () {
    return {
      openDialog: false,
      loaded: false,
      imageUrl: process.env.VUE_APP_IMG_URL
    }
  },
  computed: {
    artworkDetails () {
      return this.artworks.map(item => {
        // const artworkImage = this.getArtworkImage(item.products)
        const productList = _.uniq(item.products.map(a => pluralize.singular(a.category))).sort().join(', ')
        const products = _.groupBy(item.products, 'category')
        return { ...item, products: products, productList: productList }
      })
    }
  },
  created: async function () {
  },
  methods: {
    cleanMessage(item) {
      return this.$sanitize(item);
    },
    getArtworkImage (products) {
      if (products.length === 1) {
        return products[0].artworkImage
      } else if (products[0].category === 'Mugs') {
        return products[0].artworkImage
      } else return products[0].artworkImage
    },
    showProducts (item) {
      this.openDialog = true
      this.selectedItem = item
    },
    closeDialog (element) {
      this.openDialog = false
    }
  }
}
</script>
<style scoped>
.artwork-title {
  font-size: 1.1rem
}
</style>
